import React from "react"
import './our-clients.scss'

class OurClients extends React.Component{
  render (){
    return (
      <article className="ourclients">
        <h1 className="ourclients__title">Nuestros Clientes</h1>
        <div className="ourclients__clients" />
      </article>
    )
  }

}

export default OurClients 

import "./aboutus.scss";
import React from "react"
import flag1 from "../../images/aboutus/flag1.png"
import flag2 from "../../images/aboutus/flag2.png"
import flag3 from "../../images/aboutus/flag3.png"
import flag4 from "../../images/aboutus/flag4.png"
import flag5 from "../../images/aboutus/flag5.png"

class AboutUs extends React.Component{
  render (){

    return (
      <article className="aboutus">
        <div className="aboutus__content-wrap">
          <div className="aboutus__title-wrap">
            <h1 className="aboutus__title">
              ¿Quiénes somos? 
            </h1>
          </div>
          <div className="aboutus__description-wrap">
            <p className="aboutus__description">
              MedSys es una compañia con más de diez años de experiencia en
              el desarrollo de soluciones tecnológicas para diversos
              sectores. En el sector médico desarrollamos software para
              cubrir de manera eficiente las necesidades de profesionales de
              diferentes especialidades. Estamos en constante evolución para
              adaptarnos a los cambios tecnológicos modernos y a las
              regulaciones estatales en manejo de información médica.
              Contamos con un amplio grupo interdisciplinario de
              profesionales encargados de desarrollar productos de alta
              calidad y brindar un excelente servicio a nuestros clientes.
            </p>
          </div>
          <div className="aboutus__flags">
            <img className="aboutus__flag" src={flag1} alt="Colombian flag" />
            <img className="aboutus__flag" src={flag2} alt="Venezuelan flag" />
            <img className="aboutus__flag" src={flag3} alt="Spanish flag" />
            <img className="aboutus__flag" src={flag4} alt="Mexican flag" />
            <img className="aboutus__flag" src={flag5} alt="Brazilian flag" />
          </div>
        </div>
      </article>
    )
  }

}

export default AboutUs

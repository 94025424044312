import "./products.scss";
import React from "react"
import Medisign from "../../images/products/medisign.png"
import Medsys from "../../images/products/medsys.png"
import { Link } from "gatsby"

class Products extends React.Component{
  render (){
    return (
      <article className="products">
        <div className="products__title-wrap">
        </div>

        <div className="products__items">
          <div className="products__item products__medsys">
            <div className="products__logo-wrap">
              <img className="products__logo" src={Medsys} alt="Medsys Logo"/>
            </div>
            <div className="products__description-wrap">
              <p className="products__description">
                Software de administración de historias clinicas, agendas y RIPS.
              </p>
            </div>

            <Link className="products__btn"  to="/medsys/">
                <span className="products__btn-wave"></span>
                  <span className="products__btn-txt">
                  Conocer más
                </span>
            </Link>
          </div>

          <div className="products__item products__medisign">
            <div className="products__logo-wrap">
              <img className="products__logo" src={Medisign} alt="Medisign Logo"/>
            </div>
            <div className="products__description-wrap">
              <p className="products__description">
                Software de administración de consentimientos informados.
              </p>
            </div>

            <Link className="products__btn"  to="/medisign/">
              <span className="products__btn-wave"></span>
                <span className="products__btn-txt">
                Conocer más
              </span>
            </Link>

        </div>
      </div>
    </article>
  )
  }

}

export default Products
